// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/imgs/techwiz_logo69-removebg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".App {\r\n    text-align: center;\r\n}\r\n\r\n.ant-layout-header {\r\n    background-color: #021e22;\r\n}\r\n\r\nh1 {\r\n    color: #54b7b4;\r\n    font-size: calc(1.3px + 2vw);\r\n}\r\n\r\nh1 b {\r\n    color: #ffff;\r\n}\r\n\r\n.site-layout-content {\r\n    background: #fff;\r\n    padding: 24px;\r\n    min-height: 840px;\r\n}\r\n\r\n.logo {\r\n    width: calc(60px + 5vw);\r\n    min-width: 80px;\r\n    height: 63.5px;\r\n    float: left;\r\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\r\n    background-color: #ffffff;\r\n    background-size: cover;\r\n}\r\n\r\n/* Flexbox layout for footer */\r\n.footer-content {\r\n    display: flex;\r\n    justify-content: space-between; /* Space between left-aligned and center content */\r\n    align-items: center;\r\n    position: relative;\r\n    padding: 10px;\r\n    background-color: #f5f5f5;\r\n}\r\n\r\n.sponsorship-images {\r\n    text-align: left;\r\n    max-width: 200px; /* To contain both image and text within a reasonable space */\r\n    margin-left: 10px; /* Space from the left edge */\r\n}\r\n\r\n.sponsorship-images img {\r\n    width: 200px; /* Keep the image a reasonable size */\r\n    height: auto; /* Maintain aspect ratio */\r\n    display: block;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.sponsorship-images p {\r\n    font-size: 10px; /* Small text size for the disclaimer */\r\n    color: #333;\r\n    line-height: 1.2;\r\n}\r\n\r\n/* Center GILT Text */\r\n.footer-text {\r\n    text-align: center;\r\n    flex: 1 1;\r\n}\r\n\r\n", ""]);
// Exports
module.exports = exports;
