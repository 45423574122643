import React, { Component } from 'react';
import 'antd/dist/antd.css';
import './App.css';
import Navbar from '../navbar/Navbar.js';
import Search from '../search/Search.js';
import Display from '../display/Display.js';
import Avatar from '../avatar/Avatar.js';
import { Layout, Row, Col, Spin } from 'antd';
import explanation from '../../apis/Explanation.js';
import axios from "axios";

const { Header, Footer, Content } = Layout;

class App extends Component {

    state = {
        explanations: null,
        showAvatar: false,
        language: window.localStorage.getItem("language") || 'pt',
        loading: false,
        localData: {
            IPv4: null,
            country_code: null,
            country_name: null,
            state: null,
            city: null,
        }
    }

    handleSubmit = async (conceptFromSearch) => {
        this.setState({
            loading: true
        })
        const response = await explanation.get('/search', {
            headers: {
                'Accept-Language': this.state.language
            },
            params: {
                query: conceptFromSearch
            }
        })
        const res = await axios.get('https://geolocation-db.com/json/7a9b1b60-6cd6-11ed-a5c7-1104687560a9')
        .catch(function(error) {
            console.log(error);
        });

        console.log(response.data)
        console.log(res ? res.data : 'could not find geolocation info')
        this.setState({
            explanations: response.data,
            localData: res ? res.data : this.state.localData,
            loading: false,
        })
    };

    handleAvatar = (value) => {
        this.setState({
            showAvatar: value
        })
    }

    handleLanguageChange = (lng) => {
        this.setState({
            language: lng
        })
        window.localStorage.setItem("language", lng)
        window.setLanguage(lng.toUpperCase())
    }

    refreshPage = () => {
        window.location.reload()
    }

    render() {
        return (
            <div className="App">
                <Layout className="layout">
                    <Header>
                        <Row justify="center">
                            <Col xs={ 6 } sm={ 5 } md={ 4 } lg={ 3 } xl={ 2 }><div className="logo" onClick={this.refreshPage} /></Col>
                            <Col xs={ 18 } sm={ 14 } md={ 15 } lg={ 16 } xl={ 18 }><h1> <b>Tech</b>Whiz </h1></Col>
                            <Col xs={ 0 } sm={ 5 } md={ 4 } lg={ 3 } xl={ 2 }><Navbar handleLanguageChange={this.handleLanguageChange} language={this.state.language}/></Col>
                        </Row>
                    </Header>
                    <Content style={{ padding: '0 50px' }}>
                        <div className="site-layout-content">
                            <Row justify="center">
                                <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 }><Search handleFormSubmit={this.handleSubmit} handleLanguageChange={this.handleLanguageChange} language={this.state.language}/></Col>
                                <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 24 }><Spin spinning={this.state.loading} size="large"></Spin></Col>
                            </Row>
                            <br />
                            <br />
                            <Row justify="center">
                                <Col xs={ 24 } sm={ 12 } md={ 12 } lg={ 14 } xl={ 14 }><Display content={this.state.explanations} handleAvatar={this.handleAvatar} handleLanguageChange={this.handleLanguageChange} language={this.state.language} localData={this.state.localData}/></Col>
                                <Col xs={ 0 } sm={ 12 } md={ 8 } lg={ 4 } xl={ 6 }><Avatar show={this.state.showAvatar}/></Col>
                            </Row>
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>
  <div className="footer-content">
    {/* Left-aligned logo and text */}
    <div className="sponsorship-images">
      <img src="/assets/imgs/EN_Co-fundedbytheEU_RGB_POS.png" alt="EU" />
      <p>Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.</p>
    </div>

    {/* Center-aligned GILT text */}
    <div className="footer-text">
      <p>GILT Â©2016</p>
    </div>
  </div>
</Footer>

                </Layout>
            </div>
        )
    }
}

export default App;
