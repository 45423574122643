import i18next from 'i18next';
import pt from './locales/pt.json'
import en from './locales/en.json'
import sl from './locales/sl.json'
import gr from './locales/gr.json'
import de from './locales/de.json'
import cy from './locales/cy.json'

i18next
    .init({
        interpolation: {
            // React already does escaping
            escapeValue: false,
        },
        fallbackLng: 'pt',
        resources: {
            pt, en, sl, gr, de, cy
        },
    })

export default i18next
