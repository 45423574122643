import React, { Component } from 'react';
import { Menu, Dropdown, Button, Modal } from 'antd';
import './Navbar.css';
import { DownOutlined } from '@ant-design/icons';
import FlagIcon from './FlagIcon.js';
import i18n from '../../i18next';
import howTo from '../../assets/imgs/howToUseEAC.png'

class Navbar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            lng: this.props.language,
            showHelp: false
        }
        this.onLanguageChanged = this.onLanguageChanged.bind(this)
    }

    componentWillUpdate(nextprops) {
        if (nextprops.language !== this.props.language) {
            this.setState({
                lng: nextprops.language
            })
        }
    }

    onLanguageChanged = ({ key }) => {
        this.setState({
            lng: key
        })
        this.props.handleLanguageChange(key)
    }

    showHelpModal = () => {
        this.setState({
            showHelp: true
        })
    }

    closeHelpModal = () => {
        this.setState({
            showHelp: false,
        })
    }


    menu = (
        <Menu onClick={this.onLanguageChanged}>
            <Menu.Item key='pt'>
                <a target="_blank" rel="noopener noreferrer">
                    <FlagIcon code='pt' size='lg' /> Português
                </a>
            </Menu.Item>
            <Menu.Item key='en'>
                <a target="_blank" rel="noopener noreferrer">
                    <FlagIcon code='gb' size='lg' /> English
                </a>
            </Menu.Item>
            <Menu.Item key='sl'>
                <a target="_blank" rel="noopener noreferrer">
                    <FlagIcon code='si' size='lg' /> Slovene
                </a>
            </Menu.Item>
            <Menu.Item key='gr'>
                <a target="_blank" rel="noopener noreferrer">
                    <FlagIcon code='gr' size='lg' /> Greek
                </a>
            </Menu.Item>
            <Menu.Item key='de'>
                <a target="_blank" rel="noopener noreferrer">
                    <FlagIcon code='de' size='lg' /> German
                </a>
            </Menu.Item>
            <Menu.Item key='cy'>
                <a target="_blank" rel="noopener noreferrer">
                    <FlagIcon code='cy' size='lg' /> Cypriot
                </a>
            </Menu.Item>
        </Menu>
    );


    render() {
        let lng = this.state.lng
        let sourceVideo = `https://www.youtube.com/embed/rB9ql0L0cUQ?cc_load_policy=1&cc_lang_pref=${lng}`
        if (lng === 'pt')
            sourceVideo = sourceVideo + '-PT'
        
        let info = <div>
            
            <p>{i18n.t('helpPage.intro', { lng })}</p>
            <ol>
                <li>{i18n.t('helpPage.one', { lng })}</li>
                <li>{i18n.t('helpPage.two', { lng })}</li>
                <li>{i18n.t('helpPage.three', { lng })}</li>
                <li>{i18n.t('helpPage.four', { lng })}</li>
                <li>{i18n.t('helpPage.five', { lng })}</li>
                <li>{i18n.t('helpPage.six', { lng })}</li>
                <li>{i18n.t('helpPage.seven', { lng })}</li>
                <li>{i18n.t('helpPage.eight', { lng })}</li>
                <li>{i18n.t('helpPage.nine', { lng })}</li>
                <li>{i18n.t('helpPage.ten', { lng })}</li>
            </ol>
            <br/>
            <center>
                <img src={howTo} className='image' alt='how to use EAC' />
                <br/>
                <iframe className='video' title='instruction video dummy' src={sourceVideo}/>
            </center>
        </div>



        return (
            <div className="Navbar">
                <Dropdown overlay={this.menu}>
                    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                        {i18n.t('navBar.language', { lng })} <DownOutlined />
                    </a>
                </Dropdown>
                <Button className='help-button' onClick={this.showHelpModal}>
                    {i18n.t('navBar.help', { lng })}
                </Button>
                <Modal title={i18n.t('navBar.help', { lng })}
                    visible={this.state.showHelp}
                    footer={<Button type="primary" key="back" onClick={this.closeHelpModal}>{i18n.t('common.button.close', { lng })}</Button>}
                    animation={false}
                    closable={false}
                    width={1000}>
                    {info}  
                </Modal>
            </div>
        )
    }
}

export default Navbar;
