import React, { Component } from 'react';
import { Input, Button } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import './Search.css';
import i18n from '../../i18next';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            term: '',
            language: this.props.language
        }
    }
    componentWillUpdate(nextprops) {
        if(nextprops.language !== this.props.language){
            this.setState({
                language: nextprops.language
            })
        }
    }

    handleChange = (event) => {
        this.setState({
            term: event.target.value
        });
    };

    handleSubmit = event => {
        this.props.handleFormSubmit(this.state.term);
        return false;
    }

    handleChangeAvatarValue = () => {
        window.translateByText(this.state.term)
    }

    render() {
        let lng = this.state.language

        let translateTermButton = <Button type="primary" 
                                    className='translate-button'
                                    onClick={this.handleChangeAvatarValue}>{ i18n.t('searchBar.translateButton', { lng }) }
                                    <PlayCircleOutlined />
                                </Button>



        return (
            <div className="Search">
                <Input.Search style={{ width: '50%' }} placeholder={ i18n.t('searchBar.placeholder', {lng}) } size="large" onChange={this.handleChange} onSearch={this.handleSubmit} enterButton/>
                <br/>
                {translateTermButton}
                <br/>
                <br/>
            </div>
        )
    }
}

export default Search;
