import React, { Component } from 'react';
import { Button, Card, Tooltip, Spin, Modal } from 'antd';
import { PlayCircleOutlined, CameraOutlined, DislikeOutlined, LikeOutlined, InfoCircleOutlined, ExpandAltOutlined } from '@ant-design/icons';
import Explanation from '../../apis/Explanation.js';
import i18n from '../../i18next';
import "antd/dist/antd.css"

class ContextBoard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            explanationObject: this.props.exp,
            loading: false,
            displayAvatar: false,
            language: this.props.language,
            summarizationObject: null,
            showSummarization: false
        }
    }

    componentWillUpdate(nextProps) {
        // Any time props.exp changes, update state.
        if (nextProps.language !== this.props.language){
            this.setState({
                language: nextProps.language
            })
        }else if (nextProps.exp !== this.props.exp) {
            this.setState({
                explanationObject: nextProps.exp,
                loading: false,
                displayAvatar: false
            })
            this.props.handleAvatar(false)

        }
    }

    handlePositiveFeedback = async () => {
        this.setState({
            loading:true
        })
        const response = await Explanation.post('/like-definition', {
            word: this.props.expression,
            definition: this.state.explanationObject.sentence,
            ipv4: this.props.localData.IPv4,
            country_code: this.props.localData.country_code,
            country_name: this.props.localData.country_name,
            location_state: this.props.localData.state,
            location_city: this.props.localData.city
        }, {
            headers: { 'Accept-Language': this.state.language } 
        })
        this.setState({
            loading: false,
            explanationObject: response.data
        })
    };

    handleNegativeFeedback = async () => {
        this.setState({
            loading:true
        })
        const response = await Explanation.post('/dislike-definition', {
            word: this.props.expression,
            definition: this.state.explanationObject.sentence,
            ipv4: this.props.localData.IPv4,
            country_code: this.props.localData.country_code,
            country_name: this.props.localData.country_name,
            location_state: this.props.localData.state,
            location_city: this.props.localData.city,
        }, {
            headers: { 'Accept-Language': this.state.language } 
        })
        this.setState({
            loading: false,
            explanationObject: response.data
        })
    };

    handleExplanationPlease = async () => {
        this.setState({
            loading:true,
        })
        const summarizationResponse = await Explanation.post('/summarizationNew', {
            query: this.props.expression,
            sentence: this.state.explanationObject.sentence
        }, {
            headers: { 'Accept-Language': this.state.language } 
        })
        console.log(summarizationResponse.data)
        this.setState({
            loading: false,
            showSummarization: true,
            summarizationObject: summarizationResponse.data
        })
    }

    closeModal = () => {
        this.setState({
            showSummarization: false,
        })
    }

    handleChangeAvatarValue = () => {
        this.setState({
            showSummarization: false
        })
        window.translateByText(this.state.explanationObject.sentence)
    }

    render(){
        let lng = this.state.language

        function dif(value){
            if(value< 3){
                return "easy"
            }
            else if(value< 7){
                return "medium"
            }
            else{
                return "hard"
            }
        }

        const tooltipText = <span>
            {i18n.t('indexTooltip.text', { lng })}
            <p>1-3 {i18n.t('indexTooltip.ez', { lng })}</p>
            <p>3-7 {i18n.t('indexTooltip.medium', { lng })}</p>
            <p>&gt;7 {i18n.t('indexTooltip.hard', { lng })}</p>
        </span>

        const feedback = 
        <div id="feedback">
            <Button type="link" onClick={this.handlePositiveFeedback}><LikeOutlined /> {this.state.explanationObject.likes}</Button>
            <Button type="link" onClick={this.handleNegativeFeedback}><DislikeOutlined /> {this.state.explanationObject.dislikes}</Button>
        </div>

        var index = <div id="score"></div>
        if(this.state.language === "pt"){
            index = <div id="score"> {i18n.t('indexTooltip.text', { lng })} &nbsp;
            <span difficulty={dif(this.state.explanationObject.score)}>{this.state.explanationObject.score}</span> &nbsp;
            <Tooltip placement="bottomLeft" title={tooltipText}><InfoCircleOutlined /></Tooltip>
        </div>
        }

        let context = null
        if(this.state.explanationObject.context) {
            context = <div>{this.state.explanationObject.context} <a href={`https://www.google.com/search?q=${this.state.explanationObject.context}&tbm=isch`} target="_blank" rel="noopener noreferrer"><CameraOutlined style={{ fontSize: '30 px'}} /></a></div>
        }

        let showAvatarButton = <Button type="primary" 
                                    onClick={this.handleChangeAvatarValue}>{ i18n.t('display.board.button.display', { lng }) }
                                    <PlayCircleOutlined />
                                </Button>

        let summModal = null
        if(this.state.summarizationObject != null && this.state.summarizationObject.length !== 0) {
            console.log(this.state.summarizationObject)

            switch (this.state.summarizationObject) {
                case 'No relevant information found on wikipedia':
                    summModal = <Modal title={i18n.t('summarization.title', { lng })}
                        visible={this.state.showSummarization} 
                        footer={<Button type="primary" key="back" onClick={this.closeModal}>{i18n.t('common.button.close', { lng })}</Button>} 
                        animation={false} 
                        closable={false}
                        width={1000}>
                            <p>{i18n.t('display.notFound.msg', { lng })}</p>
                    </Modal>
                    break;
                case 'Ambiguous word in wikipedia sources':
                    summModal = <Modal title={i18n.t('summarization.title', { lng })}
                        visible={this.state.showSummarization} 
                        footer={<Button type="primary" key="back" onClick={this.closeModal}>{i18n.t('common.button.close', { lng })}</Button>} 
                        animation={false} 
                        closable={false}
                        width={1000}>
                            <p>{i18n.t('display.ambiguousWord.msg', { lng })}</p>
                        </Modal>
                    break;
                default:
                    summModal = <Modal title={i18n.t('summarization.title', { lng })}
                                    visible={this.state.showSummarization}
                                    footer={<Button type="primary" key="back" onClick={this.closeModal}>{i18n.t('common.button.close', { lng })}</Button>} 
                                    animation={false}
                                    closable={false}
                                    width={1000}>
                        {this.state.summarizationObject.map((element, i) => {
                            return <Card key={i} title={`${i+1}. ${this.props.expression}`} headStyle={{textAlign:'left'}}>
                                <p>{element.sentence}</p>
                                <div id="score"> {i18n.t('indexTooltip.text', { lng })} &nbsp;
                                    <span difficulty={dif(element.score)}>{element.score}</span> &nbsp;
                                    <Tooltip placement="bottomLeft" title={tooltipText}><InfoCircleOutlined /></Tooltip>
                                </div>
                                <div id='showVis' style={{ float: 'right'}}>
                                    {showAvatarButton}
                                </div>
                            </Card>
                        })}
                    </Modal>
                    break;
            }
        } else {
            summModal = <Modal title={i18n.t('summarization.title', { lng })}
                                visible={this.state.showSummarization} 
                                footer={<Button type="primary" key="back" onClick={this.closeModal}>Close</Button>} 
                                animation={false} 
                                closable={false}
                                width={1000}>
                    <p>{i18n.t('display.notFound.msg', { lng })}</p>
                </Modal>
        }

        let card = 
        <Card type='inner' title={`${this.props.index+1}. ${this.props.expression}`} headStyle={{textAlign:'left'}} style={{ width: '90%' }} extra={context}>
            <p>{this.state.explanationObject.sentence}  &nbsp; <a href={`https://www.google.com/search?q=${this.state.explanationObject.sentence}&tbm=isch`} target="_blank" rel="noopener noreferrer"><CameraOutlined style={{ fontSize: '30 px'}} /></a></p>
            <p>
                {showAvatarButton}
                {index}
                {feedback}
            </p>
            <div>
                <Button type="primary" onClick={this.handleExplanationPlease} style={{ float: 'center'}}>{i18n.t('summarization.showButton', { lng })} <ExpandAltOutlined /></Button>
                {summModal}
            </div>
        </Card>
        

        return(
            <div id={this.props.index}>
                <Spin spinning={this.state.loading} size="large">
                    {card}
                    <br />
                </Spin>
            </div>

        )
    }
}

export default ContextBoard;
